import { template as template_e158a4aef8694a63ae6091f81aef00e3 } from "@ember/template-compiler";
const FKLabel = template_e158a4aef8694a63ae6091f81aef00e3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
