import { template as template_28c71ba72a8c43338ea7d1cb1873815c } from "@ember/template-compiler";
const FKControlMenuContainer = template_28c71ba72a8c43338ea7d1cb1873815c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
